@import url(https://fonts.googleapis.com/css2?family=East+Sea+Dokdo);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Outline);
@import url(https://fonts.googleapis.com/css2?family=Monoton);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap);
body[data-page="home"]{
  overflow-x: hidden;
}

body[data-page="home"] .App{
  --background: #003bb8;
  background-image: linear-gradient(120deg, #003bb8 0%, #269edb 100%);
}

body[data-page="home"] .footer{
  color: #fefefe;
  background: #1b1b1b;
}

.home {
  width: 100%;
  max-width: 100vw;
  /* height: calc(100vh - 80px); */
  /* margin-bottom: 180px; */
}

.intro {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.intro *::selection{
  background: inherit;
}

.introCards *::selection{
  background: inherit;
}

.introCards{
  margin: 50px 80px;
  position: relative;
  width: 40%;
  max-height: 100vh;
  cursor: pointer;
}

.introCard {
  position: absolute;
  transform-origin: bottom right;
  transform: rotateZ(6deg);
  opacity: 0;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 13px;
  min-width: 560px;
  width: 90%;
  max-width: 650px;
  
  min-height: 360px;
  background: #2a2a32;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s all ease-in-out;
}

.followed{
  height: 360px;
  overflow: hidden;
}

.circles {
  display: flex;
  /* padding: 15px 0 15px 13px; */
}

.navCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fefefe;
  margin: 0 4px;
}

.red {
  background: #ed4040;
}

.yellow {
  background: #ecb62c;
}

.green {
  background: #26e05a;
}

.procode {
  margin-top: 20px;
  /* font-size: 1.5em; */
  font-size: 19.5px;
  line-height: 25px;
  color: #fefefe;
}

.CodeMirror-line {
  overflow: visible;
  white-space: pre-wrap;
  word-break: normal;
  margin: 0;
}

.front{
  opacity: 1;
  transform: rotateZ(0);
  z-index: 10;
}

.followed{
  opacity: 0.5;
  transform-origin: bottom right;
  transform: rotateZ(5deg);
  box-shadow: none;
  z-index: 8;
}

.fall{
  transform-origin: bottom right;
  box-shadow: none;
  z-index: 12;
  -webkit-animation: falldown 1s ease-in-out;
          animation: falldown 1s ease-in-out;
}

@-webkit-keyframes falldown {
  0%{
    opacity: 1;
    transform: rotateZ(0);
  }

  70%{
    opacity: 0.5;
  }

  90%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0.9);
  }

  100%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0);
  }
}

@keyframes falldown {
  0%{
    opacity: 1;
    transform: rotateZ(0);
  }

  70%{
    opacity: 0.5;
  }

  90%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0.9);
  }

  100%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0);
  }
}

.profileImage {
  position: relative;
  right: -60px;
  width: 900px;
  height: 840px;
  display: grid;
  place-items: baseline end;
}

.introImage{
  position: relative;
  z-index: 3;
  /* top: 0; */
  /* left: 0; */
  max-width: 100%;
  max-width: 500px;
  height: auto;
  border: 6px solid #f7e9e9;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0px 0px 20px rgba(7, 8, 18, 0.4));
          filter: drop-shadow(0px 0px 20px rgba(7, 8, 18, 0.4));
  transform-origin: center;
  -webkit-animation: twiddle 5s linear infinite alternate;
          animation: twiddle 5s linear infinite alternate;
}

@-webkit-keyframes twiddle {
  from{
    transform: rotateZ(-5deg);
  }

  to{
    transform: rotateZ(5deg);
  }
}

@keyframes twiddle {
  from{
    transform: rotateZ(-5deg);
  }

  to{
    transform: rotateZ(5deg);
  }
}

.ringCircle{
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 500px;
  height: 500px;
  transform-origin: center;
  border: 2px solid rgba(19, 20, 33, 0.5);
  border-radius: 50%;
}

.innerCircle{
  transform: scale(1.3);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-animation: wheel 10s linear infinite;
          animation: wheel 10s linear infinite;
}

@-webkit-keyframes wheel{
  from{
    transform: scale(1.3) rotateZ(0);
  }

  to{
    transform: scale(1.3) rotateZ(360deg);
  }
}

@keyframes wheel{
  from{
    transform: scale(1.3) rotateZ(0);
  }

  to{
    transform: scale(1.3) rotateZ(360deg);
  }
}

.planetCircle{
  position: relative;
  z-index: 2;
  transform-origin: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgba(43, 43, 43, 0.3);
  -webkit-animation: planets 10s linear infinite;
          animation: planets 10s linear infinite;
}

@-webkit-keyframes planets {
  from{
    transform: rotateZ(0deg);
  }

  to{
    transform: rotateZ(-360deg);
  }

}

@keyframes planets {
  from{
    transform: rotateZ(0deg);
  }

  to{
    transform: rotateZ(-360deg);
  }

}

.innerCircle .planetCircle{
  /* cursor: pointer; */
}

.innerCircle .planetCircle:nth-child(1){
  margin-left: -23px;
}

.innerCircle .planetCircle:nth-child(2){
  margin-right: -23px;
}

.outerCircle{
  transform: scale(1.8);
  z-index: 1;

  border-width: 1px;
  -webkit-animation: wheel2 20s linear infinite;
          animation: wheel2 20s linear infinite;
}

@-webkit-keyframes wheel2{
  from{
    transform: scale(1.8) rotateZ(0);
  }

  to{
    transform: scale(1.8) rotateZ(360deg);
  }
}

@keyframes wheel2{
  from{
    transform: scale(1.8) rotateZ(0);
  }

  to{
    transform: scale(1.8) rotateZ(360deg);
  }
}

.outerCircle .planetCircle{
  position: absolute;
  width: 33px;
  height: 33px;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}

.outerCircle .planetCircle:nth-child(1){
  top: 50%;
  left: 0;
  margin-left: -16.5px;
}

.outerCircle .planetCircle:nth-child(2){
  top: 0;
  left: 25%;
  margin-left: 16.5px;
}

.outerCircle .planetCircle:nth-child(3){
  top: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(4){
  top: 50%;
  right: 0;
  margin-right: -16.5px;
}

.outerCircle .planetCircle:nth-child(5){
  bottom: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(6){
  bottom: 0;
  left: 25%;
  margin-left: 16.5px;
}

@media only screen and (max-width: 900px) {
  .home{
    height: auto;
    overflow: hidden;
  }

  .intro{
    flex-direction: column-reverse;
    justify-content: unset;
    padding-top: 0;
    align-items: center;
    vertical-align: middle;
  }

  .profileImage{
    transform-origin: center;
    height: 650px;
    --winWidth: 0.55;
    transform: scale(var(--winWidth));
    right: 0;
    place-items: baseline center;
  }

  .introCards{
    /* display: none; */
    width: 90%;
    height: 300px;
    margin: 0;
    transform-origin: left center;
    transform: scale(0.65);
    align-self: flex-end;
  }

  .procode{
    font-size: 17px;
  }
}


.CodeMirror{
  font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  font-weight: 350;
  font-size: 18px;
  color: #abb2bf;
  background-color: #282c34
}

.CodeMirror-selected {
  background-color: #3e4451
}

.CodeMirror-gutter, .CodeMirror-gutters {
  border: none;
  background-color: #282c34
}

.CodeMirror-linenumber, .CodeMirror-linenumbers {
  color: #5c6370 !important;
  background-color: transparent
}

.CodeMirror-lines {
  color: #abb2bf !important;
  background-color: transparent
}

.CodeMirror-cursor {
  border-left: 2px solid #56b6c2 !important
}

.CodeMirror-matchingbracket, .CodeMirror-matchingtag {
  border-bottom: 2px solid #56b6c2;
  color: #abb2bf !important;
  background-color: transparent
}

.CodeMirror-nonmatchingbracket {
  border-bottom: 2px solid #e06c75;
  color: #abb2bf !important;
  background-color: transparent
}

.CodeMirror-foldgutter, .CodeMirror-foldgutter-folded, .CodeMirror-foldgutter-open, .CodeMirror-foldmarker {
  border: none;
  text-shadow: none;
  color: #5c6370 !important;
  background-color: transparent
}

.CodeMirror-activeline-background {
  background-color: rgba(153, 187, 255, .04)
}

.cm-header {
  color: #e06c75
}

.cm-quote {
  color: #5c6370;
  font-style: italic
}

.cm-negative {
  color: #e06c75
}

.cm-positive {
  color: #e06c75
}

.cm-strong {
  color: #d19a66;
  font-weight: 700
}

.cm-header .cm-strong {
  color: #d19a66;
  font-weight: 700
}

.cm-em {
  color: #c678dd;
  font-style: italic
}

.cm-header .cm-em {
  color: #c678dd;
  font-style: italic
}

.cm-tag {
  color: #e06c75
}

.cm-attribute {
  color: #d19a66
}

.cm-link {
  color: #98c379;
  border-bottom: solid 1px #98c379
}

.cm-builtin {
  color: #e06c75
}

.cm-keyword {
  color: #c678dd
}

.cm-def {
  color: #e5c07b
}

.cm-atom {
  color: #d19a66
}

.cm-number {
  color: #d19a66
}

.cm-property {
  color: #56b6c2
}

.cm-qualifier {
  color: #d19a66
}

.cm-variable {
  color: #e06c75
}

.cm-string {
  color: #98c379
}

.cm-punctuation {
  color: #abb2bf
}

.cm-operator {
  color: #56b6c2
}

.cm-meta {
  color: #abb2bf
}

.cm-bracket {
  color: #abb2bf
}

.cm-comment {
  color: #5c6370;
  font-style: italic
}

.cm-error {
  color: #e06c75
}

.cm-m-css.cm-variable {
  color: #828997
}

.cm-m-css.cm-property {
  color: #abb2bf
}

.cm-m-css.cm-atom {
  color: #56b6c2
}

.cm-m-css.cm-builtin {
  color: #56b6c2
}

.cm-m-lua.cm-variable {
  color: #56b6c2
}

.projects{
  height: auto;
  /* background: rgba(17, 17, 17, 0.25); */
}

.protitle{
  display: grid;
  place-items: center;
  height: 150px;
}

.protitle span{
  color: #fefefe;
  font-size: 60px;
  font-weight: bold;
  font-family: inherit;
}

.proList{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.projCont{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.projText{
  width: 50%;
}

.projtitle{
  margin-bottom: 40px;
}

.projdesc{
  color: #edeaea;
  font-size: 1.2em;
}

.projtitle span{
  font-size: 48px;
  font-weight: bold;
  color: #1b1c1d;
}

.projImage{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 200px; */
}

.projImage img{
  max-width: 80%;
  /* max-height: 100%; */
  height: auto;
}

.header {
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 5;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  height: 100%;
}

.logo,
.headNav {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo span {
  height: 100%;
  font-weight: bolder;
  font-size: 1.4em;
  /* height: 25px; */
  height: auto;
  color: #f9f4f4;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

.headNav {}

.navList {
  display: flex;
  justify-content: space-between;
}

.navList span {
  font-size: 1.2em;
  margin: auto 40px;
  color: #f9f4f4;
}

.sideBar {
  display: none;
}

.sideIcon {
  color: #fefefe;
}

.sideMenu .MuiPaper-root {
  background: var(--background);;
  color: #f9f4f4;
  z-index: 10;
}

@media only screen and (max-width: 900px) {
  .sideBar {
    display: block;
  }

  .headerContainer {
    padding: 0 30px;
  }

  .navList {
    display: none;
  }

  .header {
    height: 60px;
  }
}

.footer {
  color: #fefefe;
  background: #1b1b1b;
  position: absolute;
  width: 100%;
  bottom: -60px;
}

.footCont {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialinks {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.3em;
}

.socialinks a {
  text-decoration: none;
}

.socialinks span {
  font-size: 0.8em;
}

@media only screen and (max-width: 900px) {
  .footCont {
    flex-direction: column-reverse;
  }

  .socialinks {
    width: 100%;
  }

  .copyrightText {
    font-size: 14px;
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid rgba(120, 120, 120, 0.7);
  }
}

.unescapeApp {
  width: 100%;
}

body[data-page="unescape"] {
  --background: #111111;
  overflow-x: hidden;
}

body[data-page="unescape"] .header {
  background: #000;
}

body[data-page="unescape"] .App {
  background: var(--background);
}

.splashScreen {
  position: relative;
  width: 100%;
  min-height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background: #000;
}

.splashScreen img {
  max-width: 100%;
  position: absolute;
  right: 0;
}

.subcount {
  color: #16c13f !important;
  font-weight: bold;
}

.physicsWin {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.splashtext {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.description {
  width: 60%;
  height: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description .title {
  position: relative;
  display: flex;
  height: 150px;
}

.titleLetters {
  position: relative;
  color: #fefefe;
  font-size: 96px;
  text-align: right;
  font-family: 'Monoton', cursive;
  /* font-family: 'Bungee Outline', cursive; */
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.titleLetters:hover {
  margin-top: -12px;
  font-size: 108px;
  font-weight: bold;
  transition: 0.2s all ease-in-out;
}

.slogan {
  color: #fefefe;
  font-size: 56px;
  /* font-weight: bold; */
  text-align: center;
  font-family: 'East Sea Dokdo', cursive;
}

.gamedesc {
  width: 75%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamedesc span {
  width: 80%;
  font-size: 18px;
  color: #f1f1f1;
  text-align: left;
}

.divider {
  height: 150px;
  overflow: hidden;
  max-width: 100%;
  margin-top: -150px;
  pointer-events: none;
  -webkit-filter: drop-shadow(0 -3px 0px rgba(74, 74, 74, 0.75));
          filter: drop-shadow(0 -3px 0px rgba(74, 74, 74, 0.75));
}

h1 {
  color: white;
}

.gamedetails {
  width: 100%;
  /* overflow-x: hidden; */
}

.gamepara {
  position: relative;
  display: flex;
  min-height: 600px;
  margin: 30px 0;
}

.floaterCont {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floater {
  --time: 20s;
  --amount: 5;
  --fill: #fefefe;
  position: absolute;
  right: 0;
  width: 300px;
  height: 300px;
  -webkit-filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.82));
          filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.82));
}

.imagecont {
  position: relative;
  width: 50%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagecont .paratitle {
  display: none;
}

.paraimage {
  position: relative;
  width: auto;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  /* border-radius: 5%; */
  border: 2px solid #dacfcf;
  /* transform-origin: bottom right; */
  /* transform: rotateZ(4deg); */
  transition: 0.5s all ease-in-out;
  z-index: 1;
}

.imageframe {
  position: relative;
  width: 400px;
  height: 400px;
  margin-top: -8px;
  max-width: 100%;
  max-height: 100%;
  background: none;
  border: 10px solid #dacfcf;
  z-index: 0;
  transition: 0.5s all ease-in-out;
}

.levelintro {
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 40px;
  margin-top: 30px;
  max-width: 100%;
  z-index: 2;
  color: rgba(221, 219, 219, 0.72);
}

#puzzleImg {
  cursor: pointer;
}

#fewords {
  text-align: center;
}

#firstCont:hover .paraimage {
  transform: rotateZ(10deg);
}

#firstCont:hover .imageframe {
  transform: rotateZ(-6deg);
}

.hiddenPhoto{
  display: none;
}

.paracol {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 100px;
  cursor: default;
  mix-blend-mode: difference;
}

.paratitle {
  font-size: 56px;
  font-weight: bold;
  color: #fcfcfc;
}

.paratext {
  font-size: 22px;
  margin: 50px 0;
  color: #e3e3e3;
}

.paratext mark {
  background: inherit;
  color: #478bf5;
}

.subscribeCont {
  width: 100%;
  min-height: 150px;
  display: grid;
  place-items: center;
}

.subscribe {
  position: relative;
  width: 500px;
  height: 60px;
  background: #090909;
  border-radius: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 2px solid rgba(66, 66, 66, 0.7);
  /* overflow: hidden; */
}

.subinp {
  width: 70%;
  height: 90%;
  margin: auto;
  margin-left: 20px;
  background: inherit;
  font-size: 1.1em;
  font-family: inherit;
  color: #d8dde2;
  border: 0 solid #fefefe;
  border-bottom: 2px;
}

.subinp:-webkit-autofill,
.subinp:-webkit-autofill:hover,
.subinp:-webkit-autofill:focus,
.subinp:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #090909 inset;
  -webkit-text-fill-color: #d8dde2;
  outline: none;
}

.subtn {
  border: none;
  background: #1c1a1a;
  width: 120px;
  height: 100%;
  border-radius: 30px;
  font-size: 1em;
  color: #3d83db;
  font-weight: bold;
  font-family: inherit;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.subtn:enabled:hover {
  transform: scale(1.05);
  background: #1977b4;
  color: #fefefe;
}

.subtxt {
  width: 100%;
  height: 50px;
  display: grid;
  place-items: center;
}

.subtxt span {
  /* padding: 0 20px; */
  width: 550px;
  max-width: 100%;
  color: rgba(196, 196, 196, 0.84);
}

.checkCont {
  position: relative;
  width: 60px;
  height: 100%;
}

.checkmark, .cross {
  z-index: 1;
  font-size: 42px !important;
  padding: 9px;
  color: #14ba3c;
  background: inherit;
  transform-origin: center;
  transform: scale(1);
  -webkit-animation: popup 1s ease-in-out;
          animation: popup 1s ease-in-out;
}

.cross {
  color: #e7272a;
}

.twit {
  z-index: 1;
  font-size: 42px !important;
  padding: 0 15px;
  margin-top: 5px;
  color: #1da1f2;
  background: inherit;
  transform-origin: center;
  transform: scale(1);
  -webkit-animation: popup 1s ease-in-out;
          animation: popup 1s ease-in-out;
  transition: 0.5s ease-in-out;
}

.twit:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.checkCont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: inherit;
  border: 4px solid #12a435;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: popupfade 1s ease-in-out;
          animation: popupfade 1s ease-in-out;
}

.crossCont::before {
  display: none;
}

@-webkit-keyframes popup {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes popup {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes popupfade {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes popupfade {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.thankscontainer {
  width: calc(100% - 60px);
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.thanks, .errortext {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  font-size: 1.5em;
  font-family: inherit;
  background: inherit;
  color: #38e685;
  transform-origin: right;
  -webkit-animation: slide 1s ease-in-out;
          animation: slide 1s ease-in-out;
}

.errortext {
  color: #e63838;
}

.errortext a {
  color: #1573d2;
}

@-webkit-keyframes slide {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.subsband {
  width: 100%;
  height: 180px;
  display: grid;
  place-items: center;
  background: #2f7de2;
}

.subbutton {
  max-width: 100%;
  width: 200px;
  padding: 15px 30px;
  background: #fefefe;
  font-size: 1.5em;
  color: #1560c2;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 2px 2px 20px 2px rgba(26, 42, 194, 0.66);
}

.subbutton a {
  color: inherit;
  text-decoration: none;
}

.puzzleCont {
  width: 100%;
  padding: 40px 0;
  /* max-height: 120px; */
  display: grid;
  place-items: center;
  background: #131313;
}

.puzzIcon {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  cursor: pointer;
}

.puzzIcon svg {
  fill: #fefefe;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}

.puzzIcon span {
  margin: 0 10px;
}

@-webkit-keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.puzzleCont span {
  font-size: 1.1em;
  color: #fefefe;
  text-align: center;
}

.puzzleCont a {
  color: #1fee57;
  font-weight: bold;
  text-decoration: underline;
}

.hiddenpuzz {
  display: flex;
  align-items: center;
  justify-content: space-around;
  -webkit-animation: fadeIn 1s ease-in-out;
          animation: fadeIn 1s ease-in-out;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hiddenpuzz span {
  font-size: 1.1em;
  color: #fefefe;
  text-align: center;
}

.secretPuzz {
  width: 200px;
  height: 10px;
  margin: 0 20px;
  background: #121212;
  padding: 10px;
  font-size: 1.1em;
  font-family: inherit;
  color: #d8dde2;
  border: 2px solid #dbd3d3;
  border-radius: 18px;
  transition: 0.3s all ease-in-out;
}

.correctAns {
  border: 2px solid #0d9a45;
  background: rgba(39, 219, 100, 0.1);
}

.wrongAns {
  border: 2px solid #c20d0d;
  background: rgba(232, 71, 71, 0.1);
}

.hiddenpuzz button {
  height: 38px;
  background: #2f7de2;
  color: #fefefe;
  font-weight: bold;
  font-family: inherit;
  border-radius: 20px;
  display: grid;
  place-items: center;
}

.hiddenpuzz button:hover {
  background: #2f7de2;
  color: #fefefe;
}

.textDivider {
  display: none;
}

.loaderEmail {
  margin: 6px 12px 0 0;
}

.prolayout {
  width: 100%;
  height: 600px;
  position: relative;
  overflow-y: hidden;
}

.prolayout::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-image: linear-gradient(rgba(17, 17, 17, 0) 0%, rgb(17, 17, 17) 100%);
  background-color: transparent;
  background-color: initial;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 10;
}

.probtn{
  position: absolute;
  bottom: 20px;
  left: 50%;
  padding: 8px 10px;
  border-radius: 10px;
  color: #fefefe;
  cursor: pointer;
  background: #171717;
  font-size: 0.8em;
  z-index: 20;
  box-shadow: 2px 2px 12px rgba(15, 15, 15, 0.4);
}

.videobar{
  width: 100%;
  display: flex;
  margin-top: 36px;
  align-items: center;
  flex-direction: column;
}

.videocard{
  width: auto;
  padding: 16px;
  max-width: 90%;
  border-radius: 12px;
  background: #141414;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.6);
}

.videocard iframe{
  width: 800px;
  height: 500px;
}

.extratweet{
  position: absolute;
  right: -64px;
  -webkit-filter: drop-shadow(2px 2px 12px rgba(0, 117, 255, 0.25));
          filter: drop-shadow(2px 2px 12px rgba(0, 117, 255, 0.25));
}

@media only screen and (max-width: 900px) {
  .splashScreen {
    background-image: url(/static/media/splashMini.b4ed0da2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 750px;
  }

  .splashScreen img {
    display: none;
  }

  .description {
    width: 100%;
    padding-top: 10px;
  }

  .description .title {
    height: auto;
  }

  .titleLetters {
    font-size: 60px;
  }

  .slogan {
    font-size: 42px;
  }

  .gamedesc {
    width: 100%;
    padding: 40px 0 20px 0;
    margin-top: 180px;
  }

  .gamedesc span {
    width: 90%;
    text-shadow: 2px 2px 2px #1f1f1f;
  }

  .subscribeCont {
    min-height: 100px;
  }

  .subscribe {
    width: 350px;
    /* height: 48px; */
  }

  .thanks{
    font-size: 1.2em;
  }

  .errortext{
    font-size: 1.03em;
  }

  .twit{
    font-size: 36px !important;
    padding: 0 8px;
  }

  .subtxt {
    position: relative;
    z-index: 2;
    background: rgba(8, 8, 8, 0.5);
  }

  .subtxt span {
    width: 360px;
    color: #dddddd;
  }

  .divider {
    margin-top: -125px;
  }

  .gamedetails {
    overflow: hidden;
  }

  .gamepara {
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 800px;
  }

  .gamepara:nth-child(even) {
    flex-direction: column-reverse;
  }

  .imagecont {
    width: 360px;
    height: 480px;
    padding: 0;
  }

  /* .imageframe {
    bottom: 0;
  } */
  .imagecont .paratitle {
    display: block;
  }

  .paracol .paratitle {
    display: none;
  }

  .paratitle {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .gamepara:nth-child(2) .paratitle {
    font-size: 30px;
  }

  .imageframe {
    width: 320px;
    height: 320px;
  }

  .paracol {
    width: 100%;
    height: auto;
    padding: 0;
    display: block;
  }

  .paratext {
    padding: 0 40px;
  }

  .paratext mark {
    color: #7dbaff;
  }

  .subsband {
    height: 150px;
  }

  .puzzleCont{
    background: #1b1b1b;
  }

  .puzzIcon{
    flex-direction: column;
  }

  .puzzIcon span{
    margin: 20px 0 0 0;
  }

  .hiddenpuzz{
    flex-direction: column;
    padding: 0 20px;
  }

  .hiddenpuzz span {
    margin-bottom: 15px;
  }

  .secretPuzz{
    margin-bottom: 15px;
  }

  .textDivider{
    display: block;
  }

  .videocard{
    padding: 8px;
  }

  .videocard iframe{
    width: 360px;
    height: 200px;
  }

  .extratweet{
    display: none;
  }
}

body{
  --fbox: 295px;
  /* --stripColor: #1d5df4; */
  --stripColor: #23fb71;
  --fcirc: #0db62b;
  --fcircle: calc(var(--fbox)/3 - 20px);
}

.progCont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headTitle {
  font-size: 48px;
  color: #fefefe;
  margin: 10px 0 0 0;
}

.flowCont {
  width: 100%;
}

.pflow {
  display: flex;
  flex-direction: column;
  position: relative;
}

.flowrow {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.flowrow:nth-child(even) {
  flex-direction: row-reverse;
}

.flowbox {
  width: 100%;
  /* width: var(--fbox); */
  height: var(--fbox);
  background: none;
  /* border: 1px solid var(--stripColor); */
  position: relative;
  overflow: hidden;
}

.proContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.proContent::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 0 #26f53d;
  border-radius: 50%;
  transition: all 200ms ease-in-out;
  -webkit-filter: drop-shadow(4px 2px 8px rgba(16, 48, 24, 0.7));
          filter: drop-shadow(4px 2px 8px rgba(16, 48, 24, 0.7));
  opacity: 0;
}

.proContent:hover::before{
  width: 110%;
  height: 110%;
  opacity: 1;
  border-width: 4px;
}

.proSpan {
  font-size: 1.6em;
  color: #fefefe;
  font-weight: 800;
}

.proContent {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--fcircle);
  height: var(--fcircle);
  background: var(--fcirc);
  border-radius: 50%;
  color: #ececec;
  z-index: 5;
  transform: translate(-50%, -40%);
  -webkit-filter: drop-shadow(4px 2px 8px rgba(15, 47, 24, 0.7));
          filter: drop-shadow(4px 2px 8px rgba(15, 47, 24, 0.7));
}

.flowrow:nth-child(even) .flowbox .proContent {
  top: unset;
  bottom: 50%;
  transform: translate(-50%, 40%);
}

.proDetail {
  position: absolute;
  top: 120%;
  left: -60px;
  width: 200px;
  text-align: center;
  transform: scale(0);
  transform-origin: center;
  transition: all 200ms ease-in-out;
}

.proContent:hover .proDetail {
  transform: scale(1);
}

.prodesc {
  color: #21ff81;
}

.prodate {
  font-size: 0.8em;
  color: #00cd4d;
}

@media only screen and (min-width: 900px) {
  .flowbox::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    background: var(--stripColor);
    z-index: 1;
    box-sizing: border-box;
  }

  .flowbox[data-isOdd="false"]::after {
    top: unset;
    bottom: 50%;
  }

  .flowbox[data-isSide="true"]::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: unset;
    bottom: unset;
    background: none;
    border: 20px solid var(--stripColor);
    border-radius: calc(var(--fbox)*130/295);
    z-index: 1;
  }

  .flowbox[data-ornt="1"]::after {
    bottom: 50%;
    right: 50%;
  }

  .flowbox[data-ornt="2"]::after {
    bottom: calc(50% - 20px);
    left: 50%;
  }

  .flowbox[data-ornt="3"]::after {
    top: calc(50% - 20px);
    left: 50%;
  }

  .flowbox[data-ornt="4"]::after {
    top: 50%;
    right: 50%;
  }

  .flowrow:first-child .flowbox:first-child::after {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 20px;
    background: var(--stripColor);
    border: none;
    border-radius: 0;
  }

  .flowbox[data-isEnd="true"]::after {
    right: unset;
    width: 100%;
    height: 20px;
    background: var(--stripColor);
    background: linear-gradient(90deg, var(--stripColor) 0%, rgba(255, 255, 255, 0) 100%);
    border: none;
    border-radius: 0;
  }

  .flowrow:nth-child(even) .flowbox[data-isEnd="true"]::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--stripColor) 100%);
  }

  .flowbox[data-isNull="true"] {
    opacity: 0;
  }
}

@media only screen and (max-width: 900px) {
  .flowbox::after {
    content: "";
    position: absolute;
    top: unset;
    left: 30%;
    width: 20px;
    height: 100%;
    background: var(--stripColor);
    z-index: 1;
  }

  .proContent{
    top: 50% !important;
    left: 30%;
    bottom: unset;
    transform: translate(-40%, -40%) !important;
  }

  .proDetail{
    top: 30%;
    left: 110%;
  }

  .flowrow:first-child .flowbox:first-child::after {
    top: 50%;
    height: 50%;
  }

  .flowbox[data-isEnd="true"]::after {
    height: 100%;
    background: linear-gradient(180deg, var(--stripColor) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  padding: 0;
  /* overflow: hidden; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #fefefe;
}

body[data-page="home"]::-webkit-scrollbar{
  background-color: #417eff;
}

body[data-page="unescape"]::-webkit-scrollbar {
  background-color: #111111;
}

body::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 4px;
  background-color: rgba(13, 75, 191, 0.82);
}

a{
  color: inherit;
}

#root {
  position: relative;
  min-height: 100vh;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  min-height: 100vh;
}

.tk-blob {
  -webkit-animation: blob-turn 30s linear infinite;
          animation: blob-turn 30s linear infinite;
  -webkit-animation: blob-turn var(--time, 30s) linear infinite;
          animation: blob-turn var(--time, 30s) linear infinite;
  fill: #7600f8;
  fill: var(--fill, #7600f8);
  transform-origin: center;
}

.tk-blob svg {
  -webkit-animation: blob-skew calc(30s * 0.5) linear 0s infinite;
          animation: blob-skew calc(30s * 0.5) linear 0s infinite;
  -webkit-animation: blob-skew calc(var(--time, 30s) * 0.5) linear 0s infinite;
          animation: blob-skew calc(var(--time, 30s) * 0.5) linear 0s infinite;
  transform-origin: center;
}

.tk-blob svg path {
  -webkit-animation: blob-scale calc(30s * 0.5) ease-in-out 0s infinite;
          animation: blob-scale calc(30s * 0.5) ease-in-out 0s infinite;
  -webkit-animation: blob-scale calc(var(--time, 30s) * 0.5) ease-in-out 0s infinite;
          animation: blob-scale calc(var(--time, 30s) * 0.5) ease-in-out 0s infinite;
  transform-origin: center;
}

@-webkit-keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }

  13% {
    transform: skewY(calc((1.8deg) * 2));
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }

  18% {
    transform: skewY(calc((2.2deg) * 2));
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }

  24% {
    transform: skewY(calc((2.48deg) * 2));
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }

  25% {
    transform: skewY(calc((2.5deg) * 2));
    transform: skewY(calc((2.5deg) * var(--amount, 2)));
  }

  26% {
    transform: skewY(calc((2.48deg) * 2));
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }

  32% {
    transform: skewY(calc((2.2deg) * 2));
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }

  37% {
    transform: skewY(calc((1.8deg) * 2));
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }

  50% {
    transform: skewY(0deg);
  }

  63% {
    transform: skewY(calc((-1.8deg) * 2));
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }

  68% {
    transform: skewY(calc((-2.2deg) * 2));
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }

  74% {
    transform: skewY(calc((-2.48deg) * 2));
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }

  75% {
    transform: skewY(calc((-2.5deg) * 2));
    transform: skewY(calc((-2.5deg) * var(--amount, 2)));
  }

  76% {
    transform: skewY(calc((-2.48deg) * 2));
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }

  82% {
    transform: skewY(calc((-2.2deg) * 2));
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }

  87% {
    transform: skewY(calc((-1.8deg) * 2));
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }

  100% {
    transform: skewY(0deg);
  }
}

@keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }

  13% {
    transform: skewY(calc((1.8deg) * 2));
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }

  18% {
    transform: skewY(calc((2.2deg) * 2));
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }

  24% {
    transform: skewY(calc((2.48deg) * 2));
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }

  25% {
    transform: skewY(calc((2.5deg) * 2));
    transform: skewY(calc((2.5deg) * var(--amount, 2)));
  }

  26% {
    transform: skewY(calc((2.48deg) * 2));
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }

  32% {
    transform: skewY(calc((2.2deg) * 2));
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }

  37% {
    transform: skewY(calc((1.8deg) * 2));
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }

  50% {
    transform: skewY(0deg);
  }

  63% {
    transform: skewY(calc((-1.8deg) * 2));
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }

  68% {
    transform: skewY(calc((-2.2deg) * 2));
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }

  74% {
    transform: skewY(calc((-2.48deg) * 2));
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }

  75% {
    transform: skewY(calc((-2.5deg) * 2));
    transform: skewY(calc((-2.5deg) * var(--amount, 2)));
  }

  76% {
    transform: skewY(calc((-2.48deg) * 2));
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }

  82% {
    transform: skewY(calc((-2.2deg) * 2));
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }

  87% {
    transform: skewY(calc((-1.8deg) * 2));
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }

  100% {
    transform: skewY(0deg);
  }
}

@-webkit-keyframes blob-scale {
  0% {
    transform: scaleX(.9) scaleY(1);
  }

  25% {
    transform: scaleX(.9) scaleY(.9);
  }

  50% {
    transform: scaleX(1) scaleY(.9);
  }

  75% {
    transform: scaleX(.9) scaleY(.9);
  }

  100% {
    transform: scaleX(.9) scaleY(1);
  }
}

@keyframes blob-scale {
  0% {
    transform: scaleX(.9) scaleY(1);
  }

  25% {
    transform: scaleX(.9) scaleY(.9);
  }

  50% {
    transform: scaleX(1) scaleY(.9);
  }

  75% {
    transform: scaleX(.9) scaleY(.9);
  }

  100% {
    transform: scaleX(.9) scaleY(1);
  }
}

