body[data-page="home"]{
  overflow-x: hidden;
}

body[data-page="home"] .App{
  --background: #003bb8;
  background-image: linear-gradient(120deg, #003bb8 0%, #269edb 100%);
}

body[data-page="home"] .footer{
  color: #fefefe;
  background: #1b1b1b;
}

.home {
  width: 100%;
  max-width: 100vw;
  /* height: calc(100vh - 80px); */
  /* margin-bottom: 180px; */
}

.intro {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.intro *::selection{
  background: inherit;
}

.introCards *::selection{
  background: inherit;
}

.introCards{
  margin: 50px 80px;
  position: relative;
  width: 40%;
  max-height: 100vh;
  cursor: pointer;
}

.introCard {
  position: absolute;
  transform-origin: bottom right;
  transform: rotateZ(6deg);
  opacity: 0;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 13px;
  min-width: 560px;
  width: 90%;
  max-width: 650px;
  
  min-height: 360px;
  background: #2a2a32;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s all ease-in-out;
}

.followed{
  height: 360px;
  overflow: hidden;
}

.circles {
  display: flex;
  /* padding: 15px 0 15px 13px; */
}

.navCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fefefe;
  margin: 0 4px;
}

.red {
  background: #ed4040;
}

.yellow {
  background: #ecb62c;
}

.green {
  background: #26e05a;
}

.procode {
  margin-top: 20px;
  /* font-size: 1.5em; */
  font-size: 19.5px;
  line-height: 25px;
  color: #fefefe;
}

.CodeMirror-line {
  overflow: visible;
  white-space: pre-wrap;
  word-break: normal;
  margin: 0;
}

.front{
  opacity: 1;
  transform: rotateZ(0);
  z-index: 10;
}

.followed{
  opacity: 0.5;
  transform-origin: bottom right;
  transform: rotateZ(5deg);
  box-shadow: none;
  z-index: 8;
}

.fall{
  transform-origin: bottom right;
  box-shadow: none;
  z-index: 12;
  animation: falldown 1s ease-in-out;
}

@keyframes falldown {
  0%{
    opacity: 1;
    transform: rotateZ(0);
  }

  70%{
    opacity: 0.5;
  }

  90%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0.9);
  }

  100%{
    opacity: 0;
    transform: rotateZ(-90deg) scale(0);
  }
}

.profileImage {
  position: relative;
  right: -60px;
  width: 900px;
  height: 840px;
  display: grid;
  place-items: baseline end;
}

.introImage{
  position: relative;
  z-index: 3;
  /* top: 0; */
  /* left: 0; */
  max-width: 100%;
  max-width: 500px;
  height: auto;
  border: 6px solid #f7e9e9;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 20px rgba(7, 8, 18, 0.4));
  transform-origin: center;
  animation: twiddle 5s linear infinite alternate;
}

@keyframes twiddle {
  from{
    transform: rotateZ(-5deg);
  }

  to{
    transform: rotateZ(5deg);
  }
}

.ringCircle{
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 500px;
  height: 500px;
  transform-origin: center;
  border: 2px solid rgba(19, 20, 33, 0.5);
  border-radius: 50%;
}

.innerCircle{
  transform: scale(1.3);
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: wheel 10s linear infinite;
}

@keyframes wheel{
  from{
    transform: scale(1.3) rotateZ(0);
  }

  to{
    transform: scale(1.3) rotateZ(360deg);
  }
}

.planetCircle{
  position: relative;
  z-index: 2;
  transform-origin: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px rgba(43, 43, 43, 0.3);
  animation: planets 10s linear infinite;
}

@keyframes planets {
  from{
    transform: rotateZ(0deg);
  }

  to{
    transform: rotateZ(-360deg);
  }

}

.innerCircle .planetCircle{
  /* cursor: pointer; */
}

.innerCircle .planetCircle:nth-child(1){
  margin-left: -23px;
}

.innerCircle .planetCircle:nth-child(2){
  margin-right: -23px;
}

.outerCircle{
  transform: scale(1.8);
  z-index: 1;

  border-width: 1px;
  animation: wheel2 20s linear infinite;
}

@keyframes wheel2{
  from{
    transform: scale(1.8) rotateZ(0);
  }

  to{
    transform: scale(1.8) rotateZ(360deg);
  }
}

.outerCircle .planetCircle{
  position: absolute;
  width: 33px;
  height: 33px;
  animation-duration: 20s;
}

.outerCircle .planetCircle:nth-child(1){
  top: 50%;
  left: 0;
  margin-left: -16.5px;
}

.outerCircle .planetCircle:nth-child(2){
  top: 0;
  left: 25%;
  margin-left: 16.5px;
}

.outerCircle .planetCircle:nth-child(3){
  top: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(4){
  top: 50%;
  right: 0;
  margin-right: -16.5px;
}

.outerCircle .planetCircle:nth-child(5){
  bottom: 0;
  right: 25%;
  margin-right: 16.5px;
}

.outerCircle .planetCircle:nth-child(6){
  bottom: 0;
  left: 25%;
  margin-left: 16.5px;
}

@media only screen and (max-width: 900px) {
  .home{
    height: auto;
    overflow: hidden;
  }

  .intro{
    flex-direction: column-reverse;
    justify-content: unset;
    padding-top: 0;
    align-items: center;
    vertical-align: middle;
  }

  .profileImage{
    transform-origin: center;
    height: 650px;
    --winWidth: 0.55;
    transform: scale(var(--winWidth));
    right: 0;
    place-items: baseline center;
  }

  .introCards{
    /* display: none; */
    width: 90%;
    height: 300px;
    margin: 0;
    transform-origin: left center;
    transform: scale(0.65);
    align-self: flex-end;
  }

  .procode{
    font-size: 17px;
  }
}
